import { isTest } from '@/helpers/config';

export function loadIntercom() {
  if (isTest) {
    return;
  }
  const script = document.createElement('script');

  script.innerText = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/u7i7f1yn';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

  document.head.appendChild(script);

  window.Intercom?.('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'u7i7f1yn',
    custom_launcher_selector: '#cluster-feedback',
  });
}
declare global {
  interface Window {
    Intercom?: <T extends 'boot' | 'update' | 'shutdown'>(
      arg0: T,
      arg1?: T extends 'boot' | 'update'
        ? Record<string, string | undefined | boolean>
        : undefined,
    ) => void;
    intercomSettings?: Record<string, any>;
  }
}
